import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { apolloClient } from "apollo";
import AppContainer from "AppContainer";
import mixpanel from "mixpanel-browser";

mixpanel.init("4206b0bc66e564e1cf58785869406359", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
  api_host: "https://x.waboba.com",
  secure_cookie: true,
});

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
      <ToastContainer
        hideProgressBar={true}
        pauseOnHover={true}
        closeButton={false}
        closeOnClick={true}
      />
    </ApolloProvider>
  );
}
